// FilteredResultsPopup.js
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import "../Style/FilteredResultsPopup.css"; // Import the CSS file

const FilteredResultsPopup = memo(({ data, onClose }) => {
  const defaultImageUrl =
    "https://assets.bwbx.io/images/users/iqjWHBFdfxIU/i85eB.chc0Cs/v1/1200x712.jpg";
  const history = useNavigate();

  const handleSlideClick = (hospitalId) => {
    const url = `/hospital-details/${hospitalId}`;
    history(url);

    // Close the popup window
    onClose();
  };

  return (
    <div className="filtered-results-popup">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h3 className="popup-title">Filtered Results</h3>
        <div className="results-container">
          {data.map((item) => (
            <div
              key={item._id}
              className="result-card"
              onClick={() => handleSlideClick(item._id)}
            >
              <img
                className="result-image"
                src={item.images[0]?.imageUrl || defaultImageUrl}
                alt={item.name}
              />
              <h4 className="result-name">{item.name}</h4>
              <p className="result-city">{item.city}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default FilteredResultsPopup;
