import React, { useState } from 'react';
import {  useLocation,  } from 'react-router-dom';
import axios from 'axios';
import '../Style/AddReview.css';

export default function AddReview() {
  
  const location = useLocation();
  const businessName = location.state?.businessName || '';
//   console.log('Business Name:', businessName);
// console.log('Location State:', location.state);


  const [formData, setFormData] = useState({
    qualityRating: 0,
    compassionRating: 0,
    cleanlinessRating: 0,
    efficiencyRating: 0,
    costRange: '0-1000',
    review: '',
    userName: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGoBack = () => {
    window.history.back();
  };
  
const handleSubmit = async (e) => {
  e.preventDefault();

  // Ensure a business is selected
  
  // console.log("idcheck", businessName);
  if (!businessName) {
    alert('Business ID is missing. Please go back and try again.');
    return;
  }

  // Set businessName to id
  // Create an object with the review data
  const reviewData = {
    businessName,
    qualityRating: formData.qualityRating,
    compassionRating: formData.compassionRating,
    cleanlinessRating: formData.cleanlinessRating,
    efficiencyRating: formData.efficiencyRating,
    costRange: formData.costRange,
    review: formData.review,
    userName: formData.userName,
  };

  try {
    // Use the id parameter directly in the API URL
    const response = await axios.post(`https://askvital.onrender.com/api/healthcare/${businessName}/reviews`, reviewData);

    if (response.data) {
      // console.log('Review added successfully:', response.data);
      // Optionally, you can handle success (e.g., show a success message)
    } else {
      // console.error('Review was added, but the response data is missing.');
    }

    // Reset the form after submission
    setFormData({
      qualityRating: 0,
      compassionRating: 0,
      cleanlinessRating: 0,
      efficiencyRating: 0,
      costRange: '0-1000',
      review: '',
      userName: '',
    });
  } catch (error) {
    // console.error('Error adding review:', error);
  }
};
    
  return (
    <div className="container021">
      <form className="Adminform01" onSubmit={handleSubmit}>
        <h2 className="Reviewheading">Add a Review</h2>

        <div className="text-left">
          <button className="back-button" onClick={() => handleGoBack()}>
            Back
          </button>
        </div>

        <label>Quality Rating:</label>
        <select
          name="qualityRating"
          value={formData.qualityRating}
          onChange={handleInputChange}
          required
        >
          {[0, 1, 2, 3, 4, 5].map((rating) => (
            <option key={rating} value={rating}>
              {rating}
            </option>
          ))}
        </select>

        <label>Compassion Rating:</label>
        <select
          name="compassionRating"
          value={formData.compassionRating}
          onChange={handleInputChange}
          required
        >
          {[0, 1, 2, 3, 4, 5].map((rating) => (
            <option key={rating} value={rating}>
              {rating}
            </option>
          ))}
        </select>

        <label>Cleanliness Rating:</label>
        <select
          name="cleanlinessRating"
          value={formData.cleanlinessRating}
          onChange={handleInputChange}
          required
        >
          {[0, 1, 2, 3, 4, 5].map((rating) => (
            <option key={rating} value={rating}>
              {rating}
            </option>
          ))}
        </select>

        <label>Efficiency Rating:</label>
        <select
          name="efficiencyRating"
          value={formData.efficiencyRating}
          onChange={handleInputChange}
          required
        >
          {[0, 1, 2, 3, 4, 5].map((rating) => (
            <option key={rating} value={rating}>
              {rating}
            </option>
          ))}
        </select>

        <label>Cost Range:</label>
        <select
          name="costRange"
          value={formData.costRange}
          onChange={handleInputChange}
          required
        >
          {['0-1000', '1000-10000', '10000-100000'].map((range) => (
            <option key={range} value={range}>
              {range}
            </option>
          ))}
        </select>

        <label>Review:</label>
        <textarea
          name="review"
          value={formData.review}
          onChange={handleInputChange}
          required
        />

        <label>Full Name:</label>
        <input
          type="text"
          name="userName" // Updated to userName
          value={formData.userName}
          onChange={handleInputChange}
          required
        />

        <div className="text-center">
          <button className='review-button' type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}
