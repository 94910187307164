// import jwt from 'jsonwebtoken';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  signInStart,
  signInSuccess,
  signInFailure,
} from '../redux/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import OAuth from '../components/OAuth';
import "../Style/Signin.css"
import Footer from "../components/Footer"
export default function SignIn() {
  const [formData, setFormData] = useState({});
  const { loading, error } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    dispatch(signInStart());
  
    try {
      const res = await fetch('https://askvital.onrender.com/api/account/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!res.ok) {
        // Handle non-2xx HTTP responses
        const errorData = await res.json();
        dispatch(signInFailure(errorData));
      } else {
        const data = await res.json();
  
        // Check if the response includes a token
        // if (data.token) {
        //   // Attach the token as a cookie to the response
        //   res.cookie('access_token', data.token, { httpOnly: true });
        // }
  
        // Dispatch the user data to your Redux store
        dispatch(signInSuccess(data));
        navigate('/');
      }
    } catch (error) {
      // Handle network errors
      dispatch(signInFailure({ message: 'Network error. Please try again.' }));
    }
  };
  
  return (
    <>
    <div className='signin-container p-3 mx-auto'>
      <h1 className='text-center font-weight-bold my-4'>Sign In</h1>
      <form onSubmit={handleSubmit} className='d-flex flex-column gap-4'>
        <input
          type='email'
          placeholder='Email'
          id='email'
          className='form-control rounded-lg p-3 my-2'
          onChange={handleChange}
        />
        <input
          type='password'
          placeholder='Password'
          id='password'
          className='form-control rounded-lg p-3 my-2'
          onChange={handleChange}
        />
        <div className='text-center'>

        <button
          disabled={loading}
          className='buttion-signin btn btn-primary rounded-lg text-uppercase'
          >
          {loading ? 'Loading...' : 'Sign In'}
        </button>
        <OAuth />
          </div>
      </form>
      <div className='d-flex gap-2 mt-5'>
        <p>Don't have an account?</p>
        <Link to='/sign-up'>
          <span className='text-primary'>Sign up</span>
        </Link>
      </div>
      <p className='text-danger mt-5'>
        {error ? error.message || 'Something went wrong!' : ''}
      </p>
    </div>
    <Footer></Footer>
    </>
  );
}
