import React from 'react';
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import { app } from '../firebase';
import { useDispatch } from 'react-redux';
import { signInSuccess } from '../redux/user/userSlice';
import { useNavigate } from 'react-router-dom';
import '../Style/Signup.css';

export default function OAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoogleClick = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);

      const result = await signInWithPopup(auth, provider);

      const res = await fetch('https://askvital.onrender.com/api/account/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fullName: result.user.displayName, // Update to fullName
          email: result.user.email,
          photo: result.user.photoURL,
        }),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      console.log(data);

      dispatch(signInSuccess(data));
      navigate('/');
    } catch (error) {
      console.error('Could not login with Google', error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  return (
    <button
      type='button'
      onClick={handleGoogleClick}
      className='signup-button btn btn-danger rounded-lg text-white p-2 text-uppercase'
    >
      Continue with Google
    </button>
  );
}
