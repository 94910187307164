import React, { useState } from "react";
import "../Style/TopCategories.css";
import { useNavigate } from 'react-router-dom';

function Categories() {
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [location, setLocation] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  const history = useNavigate();

  const toggleLocationPopup = () => {
    setShowLocationPopup(!showLocationPopup);
  };

  const handleSearch = () => {
    if (!selectedCategory) {
      alert("Please select a category before searching.");
      return;
    }

    if (!location) {
      alert("Please enter a location before searching.");
      return;
    }

    const apiUrl = "https://askvital.onrender.com/api/healthcare";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response Data:", data);

        // Filter data based on the entered location and selected category
        const trimmedEnteredLocation = location.trim().toLowerCase();

        const filteredData = data.filter((item) => {
          const trimmedItemCity = item.city.trim().toLowerCase();
          const trimmedItemCategory = item.categories.trim().toLowerCase();

          return (
            trimmedItemCity === trimmedEnteredLocation &&
            trimmedItemCategory === selectedCategory
          );
        });

        if (filteredData.length > 0) {
          // If matching results are found, show them
          setSearchResults(filteredData);
          console.log(filteredData);
        } else {
          alert(`No results found for ${selectedCategory} in ${trimmedEnteredLocation}.`);
          setSearchResults(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  function handleSlideClick(hospitalId) {
    const url = `/hospital-details/${hospitalId}`;
    // Assuming you have access to the history object
    // from React Router to navigate to the hospital details page
    history(url);
  }

  return (
    <>
      <div className="catagerige">
        <button
          onClick={() => {
            setSelectedCategory("hospital"); // Update the category name to match your data
            setShowLocationPopup(true);
          }}
        >
          <i className="fa-solid fa-hospital"></i> Hospitals
        </button>
        <button
          onClick={() => {
            setSelectedCategory("clinic"); // Update the category name to match your data
            setShowLocationPopup(true);
          }}
        >
          <i className="fa-solid fa-suitcase-medical"></i> Clinics
        </button>
        <button
          onClick={() => {
            setSelectedCategory("lab"); // Update the category name to match your data
            setShowLocationPopup(true);
          }}
        >
          <i className="fa-solid fa-vials"></i> Labs
        </button>
        <button
          onClick={() => {
            setSelectedCategory("pharmacy"); // Update the category name to match your data
            setShowLocationPopup(true);
          }}
        >
          <i className="fa-solid fa-pills"></i> Pharmacy
        </button>
      </div>

      {showLocationPopup && (
        <div className="location-popup">
          <input
            type="text"
            placeholder="Enter your location"
            aria-label="Location"
            onChange={(e) => setLocation(e.target.value)}
          />
          <div className="nearby">
            <i className="fa-solid fa-location-crosshairs"></i> Nearby
          </div>
          <div className="buttonc-contaner">

          <button
            className="categories-button-search btn-1 btn btn-primary"
            onClick={handleSearch}
          >
            Search
          </button>

          <button
            className="categories-button-search btn-1 btn btn-danger"
            onClick={toggleLocationPopup}
            >
            Close
          </button>
            </div>
          {searchResults && (
            <div>
              <h2>Search Results for {selectedCategory}:</h2>
              <ul>
                {searchResults.map((result) => (
                  <li key={result._id} onClick={() => handleSlideClick(result._id)}>
                    {result.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Categories;
