import React from "react";
import "../Style/Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h5>About Us</h5>
            <p>
              Learn more about our medical community and commitment to
              healthcare.
            </p>
          </div>
          <div className="col-md-3">
            <h5>Contact Information</h5>
            <ul className="list-unstyled">
              <li>
                <i className="fas fa-map-marker-alt"></i> 123 Medical Street,
                City
              </li>
              <li>
                <i className="fas fa-phone"></i> +123 456 7890
              </li>
              <li>
                <i className="fas fa-envelope"></i> info@medicalsite.com
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Services</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="">Find a Hospital</Link>
              </li>
              <li>
                <Link to="/">Clinic Locations</Link>
              </li>
              <li>
                <Link to="/AdminLogin">Pharmacy</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Follow Us</h5>
            <ul className="list-unstyled">
              <li>
                <a href="www.facebook.com">
                  <i className="fab fa-facebook"></i> Facebook
                </a>
              </li>
              <li>
                <a href="www.twitter.com">
                  <i className="fab fa-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a href="www.instagram.com">
                  <i className="fab fa-instagram"></i> Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="text-center">
                © {new Date().getFullYear()} Askvital || NetCreator. All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
