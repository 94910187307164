// LocationFilter.js
import React, { useState, useEffect } from 'react';

function LocationFilter({ data, onFilterChange }) {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [uniqueCities, setUniqueCities] = useState([]);

  useEffect(() => {
    const citiesSet = new Set(data.map(item => item.city));
    const uniqueCitiesArray = [...citiesSet];
    if (uniqueCitiesArray.length > 0) {
      setSelectedLocation(uniqueCitiesArray[0]);
      filterResults(uniqueCitiesArray[0]);
    }
    setUniqueCities(uniqueCitiesArray);
  }, [data]);

  const handleLocationChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLocation(selectedValue);
    filterResults(selectedValue);
  };

  const filterResults = (location) => {
    onFilterChange(location);
  };

  return (
    <div className='rating-container'>
      <label htmlFor="location">Select Location:</label>
      <select id="location" value={selectedLocation} onChange={handleLocationChange}>
        {uniqueCities.map((city, index) => (
          <option key={index} value={city}>
            {city}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LocationFilter;
