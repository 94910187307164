import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

const CityHospital = memo(({ city, hospitals }) => {
  const history = useNavigate();

  if (!hospitals || !Array.isArray(hospitals) || hospitals.length === 0) {
    console.warn(`No hospitals found for ${city}`);
    return null;
  }

  const carouselId = `carousel-${city.toLowerCase().replace(/\s+/g, '-')}`;
  const cardsPerRow = {
    xs: 1,
    sm: 1,
    md: 4,
  };

  function handleSlideClick(hospital) {
    if (hospital && hospital._id) {
      const url = `/hospital-details/${hospital._id}`;
      history(url);
    }
  }

  return (
    <section className="city-container pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="mb-3">{city}</h3>
          </div>
          <div className="col-12 text-right">
            <a className="btn btn-primary mb-3 mr-1" href={`#${carouselId}`} role="button" data-slide="prev">
              <i className="fa fa-arrow-left"></i>
            </a>
            <a className="btn btn-primary mb-3" href={`#${carouselId}`} role="button" data-slide="next">
              <i className="fa fa-arrow-right"></i>
            </a>
          </div>
          <div className="col-12">
            <div id={carouselId} className="carousel slide" data-ride="carousel" data-wrap="true">
              <div className="carousel-inner">
                {hospitals.map((hospital, index) => (
                  <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                    <div className="row">
                      {[...Array(cardsPerRow.md)].map((_, cardIndex) => {
                        const hospitalIndex = index * cardsPerRow.md + cardIndex;
                        const hospitalData = hospitals[hospitalIndex] || null;

                        if (
                          hospitalData &&
                          hospitalData.images &&
                          hospitalData.name &&
                          hospitalData.speciality
                        ) {
                          return (
                            <div
                              key={hospitalIndex}
                              className={`col-md-${12 / cardsPerRow.md} col-sm-12 mb-3`}
                              onClick={() => handleSlideClick(hospitalData)}
                            >
                              <div className="card">
                                <img
                                  className="img-fluid"
                                  alt={`Hospital ${hospitalIndex + 1}`}
                                  src={
                                    hospitalData.images?.[0]?.imageUrl ||
                                    'https://assets.bwbx.io/images/users/iqjWHBFdfxIU/i85eB.chc0Cs/v1/1200x712.jpg'
                                  }
                                />
                                <div className="card-body">
                                  <h4 className="card-title">{hospitalData.name}</h4>
                                  <p className="card-text">{`Speciality: ${hospitalData.speciality}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default CityHospital;
