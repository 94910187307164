// Filters.jsx

import React, { useState, useEffect } from 'react';
import RatingFilter from './RatingFilter';
import CategoryFilter from './CategoryFilter';
import LocationFilter from './LocationFilter';
import FilteredResultsPopup from './FilteredResultsPopup';
import '../Style/Filters.css';

function Filters() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://askvital.onrender.com/api/healthcare');
      const result = await response.json();

      if (Array.isArray(result) && result.length > 0) {
        const uniqueCategories = [...new Set(result.map(item => item.categories))];
        console.log('Unique Categories:', uniqueCategories);
        setData(result);
        setCategories(uniqueCategories);
      } else {
        console.error('Invalid API response. Ensure that the response is an array with items.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (filteredResults) => {
    setFilteredData(filteredResults);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSearch = () => {
    const filteredResults = data.filter(item => {
      return (
        (!selectedLocation || item.city === selectedLocation) &&
        (!selectedCategory || item.categories === selectedCategory)
        // Add additional filters as needed
      );
    });

    setFilteredData(filteredResults);
    setShowResults(true);
  };

  const handleReset = () => {
    setShowResults(false);
    setFilteredData([]);
    setSelectedLocation('');
    setSelectedCategory('');
    // Reset other filter states as needed
  };

  const toggleMobileFilter = () => {
    setIsMobileFilterVisible(!isMobileFilterVisible);
  };

  return (
    <div className="main-layout">
      <div className="mobile-filter-toggle">
        <button className="toggleMobileFilterbutton" onClick={toggleMobileFilter}>
          {isMobileFilterVisible ? 'Hide Filters' : 'Show Filters'}
        </button>
      </div>
      <div className={`filters ${isMobileFilterVisible ? 'show' : ''}`}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="filter-item">
              {data.length > 0 && data[0]?.reviews && (
                <RatingFilter reviews={data[0].reviews} onFilterChange={handleFilterChange} />
              )}
            </div>
            <div className="filter-item">
              {categories.length > 0 && (
                <CategoryFilter categories={categories} onFilterChange={handleCategoryChange} />
              )}
            </div>
            <div className="filter-item">
              <LocationFilter data={data} onFilterChange={handleLocationChange} />
            </div>
            <div className="filter-item">
              <button onClick={handleSearch}>Search</button>
              <button  className="reset-button" onClick={handleReset}>Reset</button>
            </div>
          </>
        )}
      </div>
      {showResults && filteredData.length > 0 && (
        <FilteredResultsPopup data={filteredData} onClose={handleReset} />
      )}
    </div>
  );
}

export default Filters;
