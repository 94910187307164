import { useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { app } from '../firebase';
import { useDispatch } from 'react-redux';
import {
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  signOut,
} from '../redux/user/userSlice';
import "../Style/Profile.css"
import Footer from "../components/Footer"

export default function Profile() {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [image, setImage] = useState(undefined);
  const [imagePercent, setImagePercent] = useState(0);
  const [imageError, setImageError] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const { currentUser, loading, error } = useSelector((state) => state.user);
  
  useEffect(() => {
    if (image) {
      handleFileUpload(image);
    }
  }, [image]);
  

  const handleFileUpload = async (image) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + image.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImagePercent(Math.round(progress));
      },
      (error) => {
        setImageError(true);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
          setFormData({ ...formData, profilePicture: downloadURL })
        );
      }
    );
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmitOut = async (e) => {
    e.preventDefault();
    try {
      dispatch(updateUserStart());
      const res = await fetch(`https://askvital.onrender.com/api/account/update/${currentUser._id}`, {
        method: 'PUT', // Change method to PUT for updates
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await res.json();
      console.log('Update Response:', data);
      if (data.success === false) {
        dispatch(updateUserFailure(data));
        return;
      }
      dispatch(updateUserSuccess(data));
      setUpdateSuccess(true);
    } catch (error) {
      console.error('Update Error:', error);
      dispatch(updateUserFailure(error));
    }
  };

  const handleDeleteAccount = async () => {
    try {
      dispatch(deleteUserStart());
      const res = await fetch(`https://askvital.onrender.com/api/account/delete/${currentUser._id}`, {
        method: 'DELETE',
      });
      const data = await res.json();
      console.log('Delete Response:', data);
      if (data.success === false) {
        dispatch(deleteUserFailure(data));
        return;
      }
      dispatch(deleteUserSuccess(data));
    } catch (error) {
      console.error('Delete Error:', error);
      dispatch(deleteUserFailure(error));
    }
  };

 const handleSignup = async () => {
    try {
      await fetch('https://askvital.onrender.com/api/account/signout');
      dispatch(signOut())
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='profile-container p-3 mx-auto'>
        <h1 className='text-3xl font-semibold text-center my-7'>Profile</h1>
        <form onSubmit={handleSubmitOut} className='flex flex-col mx-4'>
          <input
            type='file'
            ref={fileRef}
            hidden
            accept='image/*'
            onChange={(e) => setImage(e.target.files[0])}
          />
          <div className='text-center'>
            <img
              src={formData.profilePicture || currentUser.profilePicture}
              alt='profile'
              className='profile-image mx-auto'
              onClick={() => fileRef.current.click()}
            />
          </div>
          <p className='progress-text'>
            {imageError ? (
              <span className='progress-text'>
                Error uploading image (file size must be less than 2 MB)
              </span>
            ) : imagePercent > 0 && imagePercent < 100 ? (
              <span className='text-danger'>{`Uploading: ${imagePercent} %`}</span>
            ) : imagePercent === 100 ? (
              <span className='text-green'>Image uploaded successfully</span>
            ) : (
              ''
            )}
          </p>
          <input
            defaultValue={currentUser.fullName}
            type='text'
            id='fullName'
            placeholder='Full Name'
            className='form-control rounded-lg p-3 my-2'
            onChange={handleChange}
          />
          <input
            defaultValue={currentUser.email}
            type='email'
            id='email'
            placeholder='Email'
            className='form-control rounded-lg p-3 my-2'
            onChange={handleChange}
          />
          <input
            type='password'
            id='password'
            placeholder='Password'
            className='form-control rounded-lg p-3 my-2'
            onChange={handleChange}
          />
          <div className='text-center'>
            <button className='btn btn-primary rounded-lg text-uppercase'>
              {loading ? 'Loading...' : 'Update'}
            </button>
          </div>
        </form>
        <div className='d-flex justify-content-between'>
          <span
            onClick={handleDeleteAccount}
            className='profile-last-text text-danger'
          >
            Delete Account
          </span>
          <span onClick={handleSignup} className='profile-last-text'>
          Sign out
        </span>
        </div>
        <p className='text-danger mt-1'>{error && 'Something went wrong!'}</p>
        <p className='text-success mt-1'>
          {updateSuccess && 'User is updated successfully!'}
        </p>
      </div>
      <Footer />
    </>
  );
}
