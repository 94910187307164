import React, { useState, useEffect } from 'react';

function RatingFilter({ reviews, onFilterChange }) {
  const [minRating, setMinRating] = useState(0);
  const [maxRating, setMaxRating] = useState(5);

  useEffect(() => {
    filterReviews(minRating, maxRating);
  }, [minRating, maxRating, reviews]);

  const handleMinRatingChange = (event) => {
    setMinRating(Number(event.target.value));
  };

  const handleMaxRatingChange = (event) => {
    setMaxRating(Number(event.target.value));
  };

  const filterReviews = (min, max) => {
    const filteredReviews = reviews.filter(
      (review) => review.qualityRating >= min && review.qualityRating <= max
    );
    onFilterChange(filteredReviews);
  };

  return (
    <div>
      <h3 className='rating-heading'>Search By Rating</h3>
      <div className='rating-container'>
      <label htmlFor="minRating">Min Rating:</label>
      <input
        type="number"
        id="minRating"
        value={minRating}
        min={0}
        max={5}
        step={0.1}
        onChange={handleMinRatingChange}
        />
      <br />
      <label htmlFor="maxRating">Max Rating:</label>
      <input
        type="number"
        id="maxRating"
        value={maxRating}
        min={0}
        max={5}
        step={0.1}
        onChange={handleMaxRatingChange}
        />
        </div>
    </div>
  );
}

export default RatingFilter;
