import React, { useState, } from 'react';
import {  useLocation,  } from 'react-router-dom';
import '../Style/AskQuestion.css'; // Import your CSS file

function AskQuestion() {
  const location = useLocation();
  const businessName = location.state?.businessName || '';

  const [formData, setFormData] = useState({
    business: '',
    question: '',
    fullName: '', // Use consistent naming
  });



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!businessName) {
      alert('Please select a business before asking a question.');
      return;
    }

    const questionData = {
      businessName,
      question: formData.question,
      fullName: formData.fullName, // Use consistent naming
    };
    // Show a loading indicator here if desired

    try {
      const response = await fetch(`https://askvital.onrender.com/api/healthcare/${businessName}/faq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(questionData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Question added successfully:', data);
        setFormData({ business: '', question: '', fullName: '' });
        // Provide user feedback for success, e.g., show a success message
      } else {
        // Provide user-friendly error message
        console.error('Error adding question:', response.statusText);
        // You may want to show an error message to the user
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error adding question:', error.message);
      // You may want to show an error message to the user
    }
  };

  return (
    <div className="ask-question-form">
      <h1>Ask a Question</h1>
      <div className="text-left">
          <button className="back-button" onClick={() => handleGoBack()}>
            Back
          </button>
        </div>
      <form onSubmit={handleSubmit}>
        {/* <label>Select Business:</label>
        <select
          name="business"
          value={formData.business}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>Select a Business</option>
          {businesses.map((business) => (
            <option key={business._id} value={business._id}>
              {business.name}
            </option>
          ))}
        </select> */}

        <label>Your Question:</label>
        <textarea
          name="question"
          value={formData.question}
          onChange={handleInputChange}
          required
        />

        {/* <label>Full Name:</label>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          required
        /> */}

        <div className="text-center">
          <button type="submit">Ask Question</button>
        </div>
      </form>
    </div>
  );
}

export default AskQuestion;
