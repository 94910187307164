import React, { useState } from 'react';
import axios from 'axios'; // Import axios if not already imported
import axiosInstance from '../axios.config';
import '../Style/AddBusiness.css';

function AddBusiness() {
  const [formData, setFormData] = useState({
    name: '',
    categories: 'hospital',
    city: '',
    fulladdress: '',
    contactnumber: '',
    timings: '',
    about: '',
    speciality: '',
    emailId: '',
    images: [],
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Define imgBBAPIKey at the beginning of the component
  const imgBBAPIKey = '440b32244f21b613caf004214fd93cf2'; // Replace with your ImgBB API key

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = async (e) => {
    try {
      const uploadedImages = e.target.files;

      if (uploadedImages.length === 0) {
        // No files were selected, do nothing.
        return;
      }

      const imageUrls = await Promise.all(
        Array.from(uploadedImages).map(async (imageFile) => {
          const formData = new FormData();
          formData.append('image', imageFile);

          const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
            params: { key: imgBBAPIKey },
          });

          return response.data?.data?.url || null;
        })
      );

      const filteredImageUrls = imageUrls.filter((url) => url !== null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...filteredImageUrls],
      }));
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
  
    try {
      const imageUrls = await Promise.all(
        formData.images.map(async (imageUrl) => {
          if (imageUrl.startsWith('https://')) {
            return imageUrl;
          }
  
          const formData = new FormData();
          formData.append('image', imageUrl);
  
          const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
            params: { key: imgBBAPIKey },
          });
  
          return response.data?.data?.url || null;
        })
      );
  
      // Log the converted image URLs
      console.log('Converted Image URLs:', imageUrls);
  
      if (imageUrls.length === formData.images.length) {
        const updatedFormData = {
          ...formData,
          images: imageUrls,
        };
  
        // Log the data to be sent to the backend
        const businessData = {
          name: updatedFormData.name,
          categories: updatedFormData.categories,
          city: updatedFormData.city,
          fulladdress: updatedFormData.fulladdress,
          contactnumber: updatedFormData.contactnumber,
          timings: updatedFormData.timings,
          about: updatedFormData.about,
          speciality: updatedFormData.speciality,
          emailId: updatedFormData.emailId,
          images: updatedFormData.images,
        };
        console.log('Data Sent to Backend:', businessData);
  
        const response = await axiosInstance.post('https://askvital.onrender.com/api/business/submit-business', businessData);
  
        // Log the response from the backend
        console.log('Backend Response:', response.data);
  
        setSuccessMessage('Business added successfully.');
  
        setFormData({
          name: '',
          categories: 'hospital',
          city: '',
          fulladdress: '',
          contactnumber: '',
          timings: '',
          about: '',
          speciality: '',
          emailId: '',
          images: [],
        });
      } else {
        setErrorMessage('Error uploading the images. Please try again.');
      }
    } catch (error) {
      console.error('Error adding business:', error.response ? error.response.data : error.message);
      setErrorMessage('Error adding the business. Please try again.');
    }
  
    setLoading(false);
  };
  
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="add-business-form">
      <h1>Add a New Business</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <label>Business Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />

        <label>Categories:</label>
        <select
          name="categories"
          value={formData.categories}
          onChange={handleInputChange}
        >
          <option value="hospital">Hospital</option>
          <option value="clinic">Clinic</option>
          <option value="pharmacy">Pharmacy</option>
          <option value="lab">Lab</option>
        </select>

        <label>City:</label>
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
        />

        <label>Full Address:</label>
        <input
          type="text"
          name="fulladdress"
          value={formData.fulladdress}
          onChange={handleInputChange}
        />

        <label>Timings:</label>
        <input
          type="text"
          name="timings"
          value={formData.timings}
          onChange={handleInputChange}
        />

        <label>Contact Number:</label>
        <input
          type="number"
          name="contactnumber"
          value={formData.contactnumber}
          onChange={handleInputChange}
        />

        <label>About:</label>
        <textarea
          name="about"
          value={formData.about}
          onChange={handleInputChange}
        />

        <label>Speciality:</label>
        <input
          type="text"
          name="speciality"
          value={formData.speciality}
          onChange={handleInputChange}
        />
        
        <label>Email ID:</label>
        <input
          type="email"
          name="emailId"
          value={formData.emailId}
          onChange={handleInputChange}
        />

        <div>
          <label htmlFor="images">Upload Images:</label>
          <input type="file" id="images" name="images" onChange={handleImageUpload} multiple required />
        </div>

        {formData.images.length > 0 && (
          <div className="image-preview">
            <p>Image Previews:</p>
            {formData.images.map((imageUrl, index) => (
              <img key={index} src={imageUrl} alt={`Preview ${index + 1}`} />
            ))}
          </div>
        )}

        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className="text-center">
          <button type="submit" disabled={loading}>
            {loading ? 'Adding Business...' : 'Add Business'}
          </button>
        </div>
      </form>
      <div className="text-center">
          <button className="back-button" onClick={() => handleGoBack()}>
            Back
          </button>
        </div>
    </div>
  );
}

export default AddBusiness;
