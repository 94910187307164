import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { BiHome } from 'react-icons/bi';
import { FaSignInAlt } from 'react-icons/fa';
import '../Style/Head.css';

export default function Header() {
  const { currentUser } = useSelector((state) => state.user);

  return (
    <div className="navbarBackground">
      <Navbar expand="lg" bg="white">
        <div className="container-fluid">
          <Navbar.Brand href="/">
            <img
              src="https://i.ibb.co/5c5X9f1/oie-Zhohzh-ISr2s-H.png"
              className="Logo"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="ml-auto">
              <ul className="nav unorder-list">
                <li className="nav-item">
                  <Nav.Link as={Link} to="/" className="nav-link text-dark AiTwotoneHome">
                    <BiHome className="card_icon01 mx-2" />
                    Home
                  </Nav.Link>
                </li>
                {currentUser ? (
                  <li className="nav-item dropdown">
                    <NavDropdown
                      title={
                        <img
                          src={currentUser.profilePicture}
                          alt="profile"
                          className="rounded-circle"
                          style={{ width: '28px', height: '28px', objectFit: 'cover' }}
                        />
                      }
                      id="navbarDropdownMenuLin"
                    >
                      <NavDropdown.Item as={Link} to="/AddBusiness">
                        Add Business
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item as={Link} to="/AddReview">
                        Add Review
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/AddQuestions">
                        Ask Question
                      </NavDropdown.Item> */}
                      <NavDropdown.Item as={Link} to="/profile">
                        Update Profile
                      </NavDropdown.Item>
                    </NavDropdown>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Nav.Link as={Link} to="/sign-in" className="nav-link text-dark">
                      <FaSignInAlt className="card-icon0 mx-2" />
                      Sign In
                    </Nav.Link>
                  </li>
                )}
              </ul>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}
