// Hospitals.js
import React, { useState, useEffect } from "react";
import CityHospital from "./CityHospital";

function Hospitals() {
  const [citiesData, setCitiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch("https://askvital.onrender.com/api/healthcare")
      .then((response) => response.json())
      .then((data) => {
        // Group hospitals by city
        const groupedByCity = data.reduce((acc, hospital) => {
          const city = (hospital.city || 'Unknown').toUpperCase();
          if (!acc[city]) {
            acc[city] = [];
          }
          acc[city].push(hospital);
          return acc;
        }, {});

        setCitiesData(Object.entries(groupedByCity));
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="hospitals-Container m-5">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {citiesData.map(([city, hospitals]) => (
            <CityHospital key={city} city={city} hospitals={hospitals} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Hospitals;

