import React, { useState, useEffect } from 'react';

function CategoryFilter({ categories, onFilterChange }) {
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    if (categories.length > 0) {
      setSelectedCategory(categories[0]);
      filterResults(categories[0]);
    }
  }, [categories]);

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
    filterResults(selectedValue);
  };

  const filterResults = (category) => {
    onFilterChange(category);
  };

  return (
    <div className='rating-container'>
      <label htmlFor="category">Select Category:</label>
      <select id="category" value={selectedCategory} onChange={handleCategoryChange}>
        {categories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CategoryFilter;
