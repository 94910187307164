import React, { memo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import AddBusiness from './pages/AddBusiness';
import AddReview from './pages/AddReview';
import AskQuestion from './pages/AskQuestions';
import HospitalDetailPage from './pages/HospitalDetailPage';

// Wrap components with React.memo
const MemoHeader = memo(Header);
const MemoHome = memo(Home);
const MemoSignIn = memo(SignIn);
const MemoSignUp = memo(SignUp);
const MemoProfile = memo(Profile);
const MemoAddBusiness = memo(AddBusiness);
const MemoAddReview = memo(AddReview);
const MemoAskQuestion = memo(AskQuestion);
const MemoHospitalDetailPage = memo(HospitalDetailPage);

export default function App() {
  return (
    <BrowserRouter>
      <MemoHeader />
      <Routes>
        <Route path='/' element={<MemoHome />} />
        <Route path='/sign-in' element={<MemoSignIn />} />
        <Route path='/sign-up' element={<MemoSignUp />} />
        <Route path='/hospital-details/:id' element={<MemoHospitalDetailPage />} />
        <Route element={<PrivateRoute />}>
          <Route path='/profile' element={<MemoProfile />} />
          <Route path='/AddBusiness' element={<MemoAddBusiness />} />
          <Route path='/AddReview' element={<MemoAddReview />} />
          <Route path='/AddQuestions' element={<MemoAskQuestion />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
