import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OAuth from '../components/OAuth';
import '../Style/Signup.css';
import Footer from '../components/Footer';

export default function SignUp() {
  const [formData, setFormData] = useState({
    fullName: '', // Update to fullName with a lowercase "n"
    email: '',
    password: '',
    phoneNumber: '',
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.fullName || !formData.phoneNumber) {
      setError('Full Name and Phone Number are required fields.');
      return;
    }

    try {
      setLoading(true);
      setError(false);
      const res = await fetch('https://askvital.onrender.com/api/account/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await res.json();
      setLoading(false);

      if (data.success === false) {
        setError(data.message);
      } else {
        navigate('/sign-in');
      }
    } catch (error) {
      setLoading(false);
      setError('Something went wrong!');
    }
  };
  

  return (
    <>
      <div className='SignUp-container p-3 mx-auto'>
        <h1 className='display-5 text-center'>Sign Up</h1>
        <form onSubmit={handleSubmit} className='d-flex flex-column p-0'>
          <div className='form-group'>
            <label htmlFor='fullName'>Full Name</label>
            <input
              type='text'
              placeholder='Full Name'
              id='fullName' // Update to fullName
              className='form-control rounded-lg p-2 my-2'
              onChange={handleChange}
              value={formData.fullName}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              placeholder='Email'
              id='email'
              className='form-control rounded-lg p-3 my-2'
              onChange={handleChange}
              value={formData.email}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              type='password'
              placeholder='Password'
              id='password'
              className='form-control rounded-lg p-3 my-2'
              onChange={handleChange}
              value={formData.password}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='phoneNumber'>Phone Number</label>
            <input
              type='text'
              placeholder='Phone Number'
              id='phoneNumber'
              className='form-control rounded-lg p-3 my-2'
              onChange={handleChange}
              value={formData.phoneNumber}
              required
            />
          </div>
          <div className='text-center'>
            <button
              disabled={loading}
              className='signup-button btn btn-primary rounded-lg p-2 text-uppercase my-2'
            >
              {loading ? 'Loading...' : 'Sign Up'}
            </button>
            <OAuth />
          </div>
        </form>
        <div className='d-flex gap-2 mt-5'>
          <p>Have an account?</p>
          <Link to='/sign-in'>
            <span className='text-primary'>Sign in</span>
          </Link>
        </div>
        <p className='text-danger mt-2'>{error && <span>{error}</span>}</p>
      </div>
      <Footer />
    </>
  );
}
