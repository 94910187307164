import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Style/HospitalDetails.css';
import Filters from '../components/Filters';

const HospitalDetailPage = React.memo(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [hospital, setHospital] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`https://askvital.onrender.com/api/healthcare/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setHospital(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching hospital details:', error);
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (hospital && hospital.fulladdress) {
      initializeMap();
    }
  }, [hospital]);

  const initializeMap = () => {
    const addr = hospital.fulladdress;
    const embed = `<iframe width='200' height='200' frameborder='0' ` +
                  `scrolling='no' marginheight='0' marginwidth='0' ` +
                  `src='https://maps.google.com/maps?q=${encodeURIComponent(addr)}&output=embed'></iframe>`;

    const placeElement = document.querySelector('.place');
    if (placeElement) {
      placeElement.innerHTML = embed;
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    const stars = Array.from({ length: fullStars }, (_, index) => (
      <span key={index}>⭐</span>
    ));

    if (halfStar) {
      stars.push(<span key="half">⭐️</span>);
    }

    return stars;
  };

  const handleProceed = () => {
    navigate("/AddReview", { state: { businessName: hospital._id } });
  };

  const handlequestionProceed = () => {
    navigate("/AddQuestions", { state: { businessName: hospital._id } });
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="hospital-detail-container">
      
      <Filters />
      <div className="content">
      <div className="text-left-1">
          <button className="back-button" onClick={() => handleGoBack()}>
            Back
          </button>
        </div>
        <div className="image-section">
          {hospital.images && hospital.images.length > 0 ? (
            hospital.images.map((image, index) => (
              <img key={index} src={image.imageUrl} alt={hospital.name} />
            ))
          ) : (
            <img src="https://assets.bwbx.io/images/users/iqjWHBFdfxIU/i85eB.chc0Cs/v1/1200x712.jpg" alt="Placeholder" />
          )}
        </div>
        <div className="header">
          <h1>{hospital.name}</h1>
          <hr />
          <h4 className='bold-address'>ADDRESS</h4>
          <p>{hospital.fulladdress}</p>
          <p>City: {hospital.city}</p>
          <div className='timming-center'>
            <p>Timings: {hospital.timings}</p>
            <p>Contact: {hospital.contactnumber}</p>
            <p>Email: {hospital.emailId}</p>
          </div>
          <hr />
        </div>

        <div className="description">
          <div className='description-container'>
            <h2>About</h2>
            <p>{hospital.about}</p>
            <h2>Speciality</h2>
            <p>{hospital.speciality}</p>
          </div>
          <div className="place"></div> {/* This is where the Google Maps embed will be inserted */}
        </div>
        <hr />
        
        <div className="reviews-section">
          <div className='review-heading'>
            <h2>Reviews</h2>
            
  <button onClick={handleProceed}>Add Review</button>

          </div>
          <hr />
          <ul className="reviews-list">
            {hospital.reviews.map((review, index) => (
              <li key={index} className="review-item">
                <p className="user">by {review.userName}</p>
                <p className="user-review">Review: {review.review}</p>
                <div className='main-rating'>
                  <div className='rating-1'>
                    <p className="rating">Quality Rating: {renderStars(review.qualityRating)}</p>
                    <p className="rating">Compassion Rating: {renderStars(review.compassionRating)}</p>
                    <p className="rating">Cleanliness Rating: {renderStars(review.cleanlinessRating)}</p>
                  </div>
                  <div className='rating-2'>
                    <p className="rating">Efficiency Rating: {renderStars(review.efficiencyRating)}</p>
                    <p className="cost">Cost Range: {review.costRange}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="faq-section">
          <div className='review-heading'>
            <h2>FAQs</h2>
            
              <button onClick={handlequestionProceed}>Add Question</button>
            
          </div>
          <hr />
          <ul className="faq-list">
            {hospital.faq.map((faq, index) => (
              <li key={index} className="faq-item">
                <p className="question"><strong>Question:</strong> {faq.question}</p>
                <p className="answer"><strong>Answer:</strong> {faq.answer}</p>
              </li>
            ))}
          </ul>
        </div>
        <hr />
      </div>
    </div>
  );
});

export default HospitalDetailPage;