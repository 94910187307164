import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import "../Style/SearchBox.css";

function HomeTopSearchBox() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const debouncedSearch = debounce(handleSearch, 300);

  async function handleSearch(e) {
    e.preventDefault();

    if (searchQuery) {
      try {
        setLoading(true);

        const response = await fetch(`https://askvital.onrender.com/api/healthcare`);
        
        if (response.ok) {
          const searchData = await response.json();

          const trimmedSearchQuery = searchQuery.trim().toLowerCase();

          const filteredData = searchData.filter((item) => {
            const trimmedItemCity = item.city.trim().toLowerCase();
            const trimmedItemCategory = item.categories.trim().toLowerCase();
            const itemName = item.name.trim().toLowerCase();

            return (
              trimmedItemCity.includes(trimmedSearchQuery) ||
              trimmedItemCategory.includes(trimmedSearchQuery) ||
              itemName.includes(trimmedSearchQuery)
            );
          });

          setSearchResults(filteredData);
        } else {
          console.error("Failed to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  const closePopup = () => {
    setSearchResults(null);
  };

  function handleSlideClick(hospitalId) {
    const url = `/hospital-details/${hospitalId}`;
    history(url);
  }

  return (
    <>
      <div className="searchbox-container">
        <img className="home-top-search-image" src="https://i.ibb.co/kXLYB20/askvital01.png" alt="seachbox-image" />
        <div className="formContainer">
          <form className="form-class" role="search" onSubmit={handleSearch}>
            <input
              className="form-control-1"
              type="search"
              placeholder="Type your city or hospital name"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                debouncedSearch(e);
              }}
            />
            <div>
              <button className="btn-1 btn btn-success" type="submit">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>

      {loading && <p>Loading...</p>}

      {searchResults && !loading && (
        <div className="pop-up">
          <div className="popup-card">
            <h2 className="SearchReasultHeading">Search Results</h2>
            <div className="row">
              {searchResults.map((result) => (
                <div key={result._id} className="col-xl-3 col-md-6">
                  <div
                    onClick={() => handleSlideClick(result._id)}
                    className="card p-2"
                    style={{ cursor: "pointer" }}
                  >
                    <h3 className="pop-heading">{result.name}</h3>
                    <p>City: {result.city}</p>
                    {result.images && result.images.length > 0 && (
                      <img
                        className="serachImage img-fluid"
                        src={result.images[0].imageUrl}
                        alt={`Image of ${result.name}`}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <button className="btn-2 btn btn-danger m-2" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default HomeTopSearchBox;
