// import Categories from '../components/Categories';
import Footer from '../components/Footer';
import HomeTopSearchBox from "../components/HomeTopSearchBox"
import Hospitals from '../components/Hospitals';
import Categories from '../components/Categories';

export default function Home() {
  return (
    <>
    {/* <Categories/> */}
    <Categories/>
    <HomeTopSearchBox />
    <Hospitals/>
      <Footer /> 
    </>
  );
}
